import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
    StyledButton,
    StyledButtonWrapper,
    StyledHeading,
    StyledImageBox,
    StyledImagesContainer,
    StyledLink,
    StyledWrapper,
} from './KioskLandingPage.styled';
import { Layout, useFlagsContext } from '@tgg/ui';
import { isSupportCentre } from '@tgg/util';
import { PublicRuntimeConfig } from 'apps/commerce/next.config.types';

const {
    publicRuntimeConfig: { APP_BASE_URL },
}: {
    publicRuntimeConfig: PublicRuntimeConfig;
} = getConfig();

const getMenuItems = (isSupportCentreEnabled: boolean) => {
    return [
        {
            path: '/why-join-the-gym-group',
            src: 'why_join_the_gym.jpg',
            text: 'why join the gym?',
        },
        {
            path: '/join-now/find-your-gym',
            src: 'buy_a_day_or_multi-day_pass.jpg',
            text: 'buy a day or multi-day pass',
        },
        {
            path: isSupportCentreEnabled
                ? 'https://support.thegymgroup.com'
                : '/faqs',
            src: 'faqs.jpg',
            text: isSupportCentreEnabled ? 'help & support' : 'faqs',
        },
        {
            path: '/gym-classes',
            src: 'classes.jpg',
            text: 'classes',
        },
        {
            path: '/forgotten-pin',
            src: 'forgotten_pin.jpg',
            text: 'forgotten pin?',
        },
        {
            path: '/stafflogin',
            src: 'staff_area.jpg',
            text: 'staff area',
        },
    ];
};

export const KioskLandingPage = () => {
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
        },
    } = useFlagsContext();
    const router = useRouter();
    const handleClick = () => router.push('/join-now/find-your-gym');

    return (
        <>
            <Head>
                <meta name="robots" content="noindex, nofollow" />
            </Head>
            <Layout>
                <StyledWrapper>
                    <StyledButtonWrapper>
                        <StyledButton text="join now" onClick={handleClick} />
                    </StyledButtonWrapper>
                    <StyledImagesContainer>
                        {getMenuItems(enabledSupportCentre).map(
                            ({ src, path, text }) => (
                                <StyledLink
                                    key={text}
                                    href={path}
                                    tabIndex={0}
                                    rel={
                                        isSupportCentre(path)
                                            ? 'noopener noreferrer'
                                            : ''
                                    }
                                >
                                    <>
                                        <StyledImageBox
                                            $imageURL={`${APP_BASE_URL}/ywnqh/images/${src}`}
                                        />
                                        <StyledHeading variant="h2">
                                            {text}
                                        </StyledHeading>
                                    </>
                                </StyledLink>
                            ),
                        )}
                    </StyledImagesContainer>
                </StyledWrapper>
            </Layout>
        </>
    );
};
